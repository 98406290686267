var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "p-1 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    class: "d-flex flex-wrap text-danger ".concat(['xs'].includes(_vm.appBreakPoint) ? '' : 'text-title')
  }, [_c('span', {
    staticClass: "mr-1 text-nowrap"
  }, [_vm._v("Tổng tiền:")]), _c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")])]), _c('div', {
    staticClass: "d-flex flex-nowrap"
  }, [_c('b-button', {
    staticClass: "py-1 px-1 px-md-2 mr-1 mr-md-2",
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": _vm.backHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])]), _c('b-button', {
    attrs: {
      "variant": "gradient border-0 py-1 px-1 px-md-2",
      "pill": "",
      "disabled": _vm.tabIndexNotSelect === _vm.tabIndex
    },
    on: {
      "click": _vm.handleShowModalConfirm
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirm')) + " ")])])], 1)])]), !_vm.isEmpty(_vm.selectedTripToAdd) ? _c('b-modal', {
    attrs: {
      "id": "modal-modify-add-flight-price",
      "title": _vm.$t('reservation.historyActions.MODIFY_ADD_FLIGHT'),
      "title-class": "text-heading-3 text-airline font-weight-bolder",
      "centered": "",
      "lazy": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showModalConfirmAddFlight,
      "hide": _vm.hideModalConfirmAddFlight
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: " mt-lg-25",
          attrs: {
            "disabled": !_vm.isConfirm || ['VJ'].includes(_vm.bookingData.source) && !_vm.priceAdd,
            "variant": _vm.isPayNow ? 'success' : 'warning',
            "pill": ""
          },
          on: {
            "click": _vm.handleSubmitAddFlight
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.isPayNow ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU') + " ")])])];
      }
    }], null, false, 2709187181)
  }, [_c('div', {
    staticClass: "text-heading-4 text-success mt-1 mb-50 mr-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.newFlight')) + ": ")]), _vm._l(_vm.selectedTripToAdd, function (itinerary, index) {
    return _c('b-card', {
      key: index,
      staticClass: "mb-sm-2 shadow-lg border-success mx-50 mx-md-2",
      attrs: {
        "body-class": "px-50 px-1 py-1"
      }
    }, _vm._l(itinerary.segments, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('div', {
        staticClass: "d-flex flex-wrap fw-700 align-items-center",
        class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
      }, [_c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(indexSegment + 1))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber)))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(itinerary.fareOptions.bookingClass))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.departure.IATACode) + _vm._s(segment.arrival.IATACode))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.status))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin))]), _c('span', [_vm._v(_vm._s(_vm.convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin))])])]);
    }), 0);
  }), _c('div', {
    staticClass: "mt-1 d-flex-between",
    class: _vm.isMobileView ? '' : 'mx-2'
  }, [_c('span', {
    staticClass: "text-black font-weight-bolder mr-25 mr-md-2",
    class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
  }, [_vm._v(" Tổng tiền hành trình đã chọn: ")]), _c('div', [_c('span', {
    staticClass: "fw-700",
    class: _vm.isMobileView ? 'font-small-4 text-nowrap' : 'font-medium-4'
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")]), _c('span', [_vm._v("(VND)")])])]), !_vm.isEmpty(_vm.selectedTripToAdd) && ['VJ', 'VZ'].includes(_vm.selectedTripToAdd[0].airline) ? _c('div', {
    staticClass: "mx-md-2"
  }, [_vm.priceAdd ? _c('div', {
    staticClass: "text-body font-weight-bold ",
    class: _vm.isMobileView ? 'font-small-3' : 'font-medium-2'
  }, [_vm.totalRefundAmountVJ > 0 ? _c('div', {
    staticClass: "d-flex-between"
  }, [_c('span', {
    staticClass: "text-black font-weight-bolder mr-25 mr-md-2",
    class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
  }, [_vm._v(" Tiền bảo lưu định danh: ")]), _c('div', [_c('span', {
    staticClass: "text-nowrap font-weight-bolder",
    class: _vm.isMobileView ? '' : 'font-medium-4'
  }, [_vm._v(" - " + _vm._s(_vm.formatCurrency(_vm.totalRefundAmountVJ)) + " ")]), _c('span', [_vm._v("(VND)")])])]) : _vm._e(), _c('div', {
    staticClass: "d-flex-between mt-2"
  }, [_c('span', {
    staticClass: "text-black font-weight-bolder mr-25 mr-md-2",
    class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
  }, [_vm._v(" Tổng tiền phải thanh toán: ")]), _c('span', {
    staticClass: "text-warning text-nowrap font-weight-bolder",
    class: _vm.isMobileView ? '' : 'font-medium-4 mr-1'
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceAdd.total)) + " (" + _vm._s(_vm.priceAdd.currency) + ") ")])])]) : _vm.errorAdd ? _c('div', {
    staticClass: "mt-75"
  }, [_c('div', {
    staticClass: "text-danger text-heading-4"
  }, [_vm._v(" Lỗi tính giá thêm hành trình, quý đại lý vui lòng thử lại thao tác hoặc liên hệ với booker để xử lý! ")]), _c('div', {
    staticClass: "text-danger font-small-4"
  }, [_vm._v(" Mã lỗi: " + _vm._s(_vm.errorAdd) + " ")])]) : _vm.priceAdd === false ? _c('div', {
    staticClass: "mt-1 text-info text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.calcPriceForChangeFlight')) + "... "), _c('p', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info",
      "small": ""
    }
  })], 1)]) : _vm._e()]) : _vm._e(), _vm.bookingData && ['VJ'].includes(_vm.bookingData.source) ? _c('CardAncillaryPayOptions', {
    staticClass: "mt-2",
    attrs: {
      "reservations-data": _vm.bookingData,
      "is-pay-now": _vm.isPayNow,
      "has-paid-trip": _vm.hasPaidTrip
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      }
    }
  }) : _vm._e(), _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }